import React from 'react'
import { graphql, Link } from 'gatsby'
import { withStyles, Grid } from '@material-ui/core'
import Layout from '../components/Layout'

import NavBar from '../components/NavBar'
import { jpg } from '../utils/strings'
import pxToRem from '../utils/pxToRem'

const itemHeight = pxToRem(700)

const styles = theme => ({
  root: {
    paddingBottom: '32px',
  },
  section: {
    height: itemHeight,
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  title: {
    fontFamily: 'Oswald, sans',
    fontWeight: 'lighter',
    fontSize: '55px',
    letterSpacing: '3px',
    color: 'black',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
    },
  },
  titleLogo: {
    width: '75%',
    height: itemHeight,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: '400px',
    },
  },
  titleImage: {
    width: '100%',
    height: itemHeight,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
  },
  overlay: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
  },
})

const Section = withStyles(styles)(props => {
  const { classes, children, image, url, title, position = 'center' } = props
  return (
    <Link to={url}>
      <h3 className={classes.title}>{title}</h3>
      <div
        className={classes.section}
        style={{
          backgroundImage: `url("${image}${jpg()}")`,
          backgroundPosition: position,
        }}
        onClick={props.onClick}
      >
        {children}
      </div>
    </Link>
  )
})

const Home = props => {
  const { data, classes } = props
  const images = data.images.edges
  return (
    <Layout>
      <NavBar />
      <div className={classes.root}>
        <Grid container>
          <Grid xs={12} item>
            <div
              className={classes.titleImage}
              style={{
                backgroundImage: `url("${images[0].node.file.url}${jpg()}")`,
              }}
              onClick={props.onClick}
            >
              <div className={classes.overlay}>
                <div
                  style={{
                    backgroundImage: `url("${data.logo.file.url}")`,
                  }}
                  className={classes.titleLogo}
                />
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            item
            container
            className={classes.root}
            justify="space-evenly"
            alignItems="flex-end"
            spacing={8}
          >
            <Grid xs={12} md={6} item style={{ width: '100%' }}>
              <Section
                title="CULINARY CONVERSATION"
                image={images[1].node.file.url}
                url="/blog"
                position="right top"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query landing {
    images: allContentfulAsset(
      filter: {
        title: { in: ["landing-header", "landing-left", "landing-right"] }
      }
      sort: { order: ASC, fields: title }
    ) {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
    logo: contentfulAsset(title: { eq: "R&R Full Logo White" }) {
      title
      file {
        url
      }
    }
  }
`

export default withStyles(styles)(Home)
